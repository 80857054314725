import React from "react";
import "./Recommendation.css";
import LegalInsights from "./LegalInsights/LegalInsights";
import TailoredMotion from "./TailoredMotion/TailoredMotion";

const Recommendation = ({ refValue }) => {
  const recommendations = {
    practiceAreas: 10,
    increaseEfficiency: 42,
    AIGeneratedDraftAccuracy: 75,
  };

  return (
    <div className="recommendation-container" ref={refValue}>
      <header className="recommendation-header">
        <p className="recommendation-subtitle">Personalized Motion Recommendations</p>
        <h1 className="recommendation-title">Customized Legal Strategies for Optimal Results</h1>
        <p className="recommendation-description">
          Welcome to the Recommending Motions page, where Atticus harnesses the power of AI to provide strategic recommendations for legal motions tailored to your case specifics. Whether you're navigating complex litigation or seeking to resolve disputes efficiently, Atticus analyzes case details,
          identifies relevant legal precedents, and generates tailored recommendations to strengthen your legal strategy.
        </p>
      </header>

      <TailoredMotion />
      <LegalInsights />

      <footer className="recommendation-footer">
        <div className="recommendation-stats">
          <div className="stat-item">
            <h3>{recommendations.practiceAreas}</h3>
            <p>practice areas</p>
          </div>
          <div className="stat-item">
            <h3>{recommendations.increaseEfficiency}%</h3>
            <p>increase in efficiency</p>
          </div>
          <div className="stat-item">
            <h3>{recommendations.AIGeneratedDraftAccuracy}%</h3>
            <p>AI generated draft accuracy</p>
          </div>
        </div>
        <p className="recommendation-footer-description">
          With JuristAI’s products as your virtual legal assistants, you can access personalized motion recommendations based on comprehensive analysis and insights, empowering you to make informed decisions and achieve optimal outcomes for your clients. Explore the innovative capabilities of our AI
          products and unlock new possibilities in your legal practice.
        </p>
      </footer>
    </div>
  );
};

export default Recommendation;
