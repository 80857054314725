import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "./../../../assets/images/logos/logo.png";
import ArrowDown from "./../../../assets/images/icons/arrow-down.png";
import Button from "../Button/Button";
import classNames from "classnames";
import "./Nav.css";

const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="nav-container">
        <Link to={"/"} className="logo">
          <img src={Logo} alt="JuristAI" />
        </Link>
        <nav className={`nav--list-container ${isMenuOpen ? "nav--open" : ""}`}>
          <ul className="nav__list">
            <li className="nav__item">
              <NavLink exact="true" to="/" className={({ isActive }) => classNames("nav__link ", { "nav__link--active": isActive })}>
                Home
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="#" aria-disabled className={({ isActive }) => classNames("nav__link button-disabled", { "nav__link--active": false })}>
                Our Products
              </NavLink>
            </li>
            <li className="nav__item nav__item--dropdown">
              <span className="nav__link dropdown-link">
                Why JuristAI? <img src={ArrowDown} className="arrow-icon" alt="Arrow Down button-disabled" />
              </span>
              <ul className="dropdown">
                <li className="dropdown__item">
                  <NavLink to="#" className={({ isActive }) => classNames("dropdown__link borderd button-disabled", { "dropdown__link--active": isActive })}>
                    Private Attorneys
                  </NavLink>
                </li>
                <li className="dropdown__item">
                  <NavLink to="#" className={({ isActive }) => classNames("dropdown__link borderd button-disabled", { "dropdown__link--active": isActive })}>
                    Small Firms
                  </NavLink>
                </li>
                <li className="dropdown__item">
                  <NavLink to="#" className={({ isActive }) => classNames("dropdown__link button-disabled", { "dropdown__link--active": isActive })}>
                    Big Firms
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav__item">
              <NavLink to="#" className={({ isActive }) => classNames("nav__link button-disabled", { "nav__link--active": false })}>
                Book A Demo
              </NavLink>
            </li>
            <li className="nav__item ">
              <NavLink to="#" className={({ isActive }) => classNames("nav__link button-disabled", { "nav__link--active": false })}>
                Blog
              </NavLink>
            </li>
            <li className="nav__item nav__item--cta">
              <Button className="button--transparent button-disabled">Login</Button>
            </li>
            <li className="nav__item nav__item--cta">
              <Button className="button--primary button-disabled ">Sign Up</Button>
            </li>
          </ul>
        </nav>
        <button className="menu-toggle" onClick={toggleMenu}>
          <span className="menu-icon"></span>
        </button>
      </div>
    </header>
  );
};

export default Nav;
