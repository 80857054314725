import React from "react";
import "./JuristAIValues.css";
import { juristAIValuesData } from "./juristAIValues";

const JuristAIValues = () => {
  return (
    <div className="home-jurist-ai-values-container">
      <div id="juristai-values">
        <h1>JuristAI's Values</h1>
        <p>Our goal today is the same as on day one: to increase access to justice for everyone. Our solutions help lawyers work more effectively, for more clients—and these principles keep us on track, together.</p>
        <div className="values-grid">
          {juristAIValuesData.map((value, index) => (
            <div key={index} className="value-card">
              <div className="jurist-icon-container">
                <img src={value.icon} className="icon-innovation" alt="no icon" />
              </div>
              <h2>{value.title}</h2>
              <span>{value.description}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JuristAIValues;
