import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "./Carousel.css";

function FeatureCarousel() {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);

  const features = [
    {
      label: "Streamline Your Workflow",
      component: <div>JuristAI provides advanced tools to streamline your workflow, making legal processes more efficient. Our solutions utilize generative AI to assist attorneys in managing their caseloads, drafting documents, and conducting research with unparalleled speed and accuracy.</div>,
    },
    {
      label: "Personalized Support",
      component: (
        <div>
          With JuristAI, you get personalized support tailored to your needs. Our AI-driven platforms offer customized assistance for federal criminal defense, start-up business documentation, and civil litigation, providing attorneys with tools that adapt to their unique cases and requirements.
        </div>
      ),
    },
    {
      label: "Advanced Legal Solutions",
      component: <div>JuristAI offers cutting-edge legal solutions powered by generative AI. From drafting complex legal documents to providing strategic insights for litigation, our products function as AI-powered law firms that complement and enhance the work of human attorneys.</div>,
    },
  ];

  const settings = {
    className: "center",
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    draggable: true,
    arrows: false,
    autoplay: true,
    pauseOnHover: true,
    beforeChange: (current, next) => setActiveIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: "40px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: "20px",
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <Slider ref={sliderRef} {...settings}>
        {features?.map((feature, index) => {
          const isActive = index === activeIndex;
          return (
            <div key={index} className="carousel-slide" onClick={() => setActiveIndex(index)}>
              <h3 className={`carousel-label ${isActive ? "active" : "inactive"}`}>{feature?.label}</h3>
            </div>
          );
        })}
      </Slider>
      <div className="carousel-content" onMouseEnter={() => sliderRef.current.slickPause()} onMouseLeave={() => sliderRef.current.slickPlay()}>
        {features[activeIndex]?.component}
      </div>
    </div>
  );
}

export default FeatureCarousel;
