import React from "react";
import emailWhiteIcon from "./../../../../../assets/images/icons/email-white-icon.png";
import linkedinWhiteIcon from "./../../../../../assets/images/icons/linkedin-white-icon.png";
import markUnderwood from "../../../../../assets/images/team/mark-underwood.png";
import "./ChiefLegalOfficer.css";

const ChiefLegalOfficer = () => {
  const markUnderwoodInfo = [
    {
      name: "Mark Underwood",
      title: "Chief Legal Officer",
      image: markUnderwood,
      email: "mark.underwood@juristai.org",
      emailSubject: "Jurist AI Inquiry",
      linkedinUrl: "https://www.linkedin.com/in/markfunderwood",
      description: "Mark Underwood is an experienced legal  professional with a background in technology and business  consulting. Over 20 years of experience, Mark has worked with Fortune 1000 companies and is a graduate of Beloit College and the Thomas M. Cooley Law School.",
    },
  ];

  return (
    <div className="team-member-additional">
      {markUnderwoodInfo.map((info, index) => (
        <div key={index} className="additional-info">
          <img src={info?.image} className="additional-info-img" alt="" />
          <h4>{info.name}</h4>
          <h6>{info.title}</h6>
          <p>{info.description}</p>
          <div className="social-accounts-chief">
            <a href={`mailto:${info.email}?subject=${info.emailSubject}`} target="_blank" rel="noopener noreferrer">
              <img src={emailWhiteIcon} alt="Email Icon" />
            </a>
            <a href={info.linkedinUrl} target="_blank" rel="noopener noreferrer">
              <img src={linkedinWhiteIcon} alt="LinkedIn Icon" />
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChiefLegalOfficer;
