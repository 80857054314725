import React from "react";
import "./TeamSection.css";
import ChiefExecutiveOfficer from "./CEO/ChiefExecutiveOfficer";
import ChiefLegalOfficer from "./CLO/ChiefLegalOfficer";
import { openPostionsData } from "./Career/careerData";
import Career from "./Career/Career";

const TeamSection = () => {
  return (
    <div className="meet-jurist-team-section">
      <div className="team-section">
        <h2>Meet the JuristAI Team</h2>
        <p> Get to know the faces behind the innovative Plaidstone experts dedicated to revolutionizing the law.</p>
        <p>We’re a team of lawyers who passionately study the data and technology-driven solutions that shape the practice of law could be improved with the help of AI. Our goal with JuristAI is to help attorneys provide excellent representation to their clients more efficiently and effectively.</p>
        <div className="team-members">
          <ChiefExecutiveOfficer />
          <div className="team-hiring-main-container">
            <ChiefLegalOfficer />
            {openPostionsData.map((data, index) => (
              <Career key={index} careerData={data} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
