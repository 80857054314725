import React from 'react';
import checkMarkIcon from './../../../../../assets/images/icons/check-line.png'
import loopIcon from './../../../../../assets/images/icons/loop-left-line.png'
import arrowRightBlackIcon from './../../../../../assets/images/icons/arrow-right-black.png'
import chatIcon from './../../../../../assets/images/icons/chat-logo.png'
import arrowRightIcon from '../../../../../assets/images/icons/arrow-right.png'
import linesIcon from '../../../../../assets/images/icons/Highlight_05.png'

import { Link } from "react-router-dom";

const LegalInsights = () => {

    const legalInsightsDataArray = [
        {
          image: checkMarkIcon,
          text: "User information loaded",
        },
        {
          image: loopIcon,
          text: "Loading case background",
        },
        {
          image: arrowRightBlackIcon,
          text: "Loading motion template",
        },
        {
          image: arrowRightBlackIcon,
          text: "Generating a top caption",
        },
        {
          image: arrowRightBlackIcon,
          text: "Researching precedents",
        },
        {
          image: arrowRightBlackIcon,
          text: "Formulating legal arguments",
        },
        {
          image: arrowRightBlackIcon,
          text: "Inserting references",
        },
      ];

  return (
    <section className="recommendation-section">
        <div className="recommendation-card">
          <div className="recommendation-content">
            <div className="card-image-wrapper">
              <img src={chatIcon} alt="Atticus Recommends" className="card-image" />
            </div>
            <h2 className="recommendation-subtitle-card">Data-Driven Legal Insights</h2>
            <p>Access data-driven insights and analysis to inform your legal strategy. Atticus leverages advanced AI algorithms to analyze case data, identify trends, and provide valuable insights that can guide your decision-making process.</p>
            <Link to={"#"} className="recommendation-link">
              Start Free Trial <img src={arrowRightIcon} alt="Atticus Recommends" className="trial-arrow" />
            </Link>
          </div>
          <div className="data-driven-section">
            <div className="card-image-wrapper-end data-driven-section-image">
              <img src={linesIcon} alt="Atticus Recommends" className="card-image" />
            </div>
            <div className="data-driven-container">
              {legalInsightsDataArray?.map((data, index) => {
                return (
                  <div className={`data-driven ${index < 2 && "data-driven-icons-margin"} `} key={index}>
                    <img src={data.image} alt="logo" />
                    <span>{data.text}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
  );
};

export default LegalInsights;
