export const footerLinksData = {
  companyLinks: [
    { title: "Home", url: "/" },
    { title: "Pricing", url: "#" },
    { title: "Individual Attorneys", url: "#" },
    { title: "Small Firms", url: "#" },
    { title: "Big Firms", url: "#" },
  ],

  solutionLinks: [
    { title: "Login", url: "#" },
    { title: "Sign Up", url: "#" },
    { title: "User Dashboard", url: "#" },
    { title: "Subscriptions", url: "#" },
    { title: "Account Settings", url: "#" },
    { title: "Manage Organisation", url: "#" },
  ],

  moreLinks: [
    { title: "Book Demo", url: "#" },
    { title: "Blog", url: "blog" },
    { title: "Privacy Policy", url: "#" },
    { title: "Terms of Service", url: "#" },
    { title: "Disclaimer", url: "#" },
  ],
};
