export const featureData = [
  {
    title: "Advanced Encryption Protocols",
    description: "Safeguard your confidential legal data with industry-standard encryption techniques, ensuring end-to-end protection against unauthorized access.",
    keyFeatures: [
      "All user access is protected by industry standard, role-based authentication",
      "Data source credentials are encrypted and stored in a secure secrets manager",
      "Single Sign On is available and integrates with your secure identity provider",
      "Customer instances and data are logically separated",
      "All access to data sources, queries, and results is logged and audited",
      "All code is thoroughly reviewed and tested before deployment to production",
      "Perimeter firewalls block unauthorized ports and protocols",
      "Network vulnerability scans are performed monthly",
      "Third-party penetration testing is performed annually",
    ],
  },
  {
    title: "Granular Access Controls",
    description: "Safeguard your confidential legal data with industry-standard encryption techniques, ensuring end-to-end protection against unauthorized access.",
    keyFeatures: [
      "Customer instances and data are logically separated",
      "All access to data sources, queries, and results is logged and audited",
      "All code is thoroughly reviewed and tested before deployment to production",
      "Perimeter firewalls block unauthorized ports and protocols",
      "Network vulnerability scans are performed monthly",
      "All user access is protected by industry standard, role-based authentication",
      "Data source credentials are encrypted and stored in a secure secrets manager",
      "Single Sign On is available and integrates with your secure identity provider",
      "Third-party penetration testing is performed annually",
    ],
  },
  {
    title: "Regulatory Compliance Assurance",
    description: "Safeguard your confidential legal data with industry-standard encryption techniques, ensuring end-to-end protection against unauthorized access.",
    keyFeatures: [
      "All user access is protected by industry standard, role-based authentication",
      "Data source credentials are encrypted and stored in a secure secrets manager",
      "Single Sign On is available and integrates with your secure identity provider",
      "Customer instances and data are logically separated",
      "All access to data sources, queries, and results is logged and audited",
      "All code is thoroughly reviewed and tested before deployment to production",
      "Perimeter firewalls block unauthorized ports and protocols",
      "Network vulnerability scans are performed monthly",
      "Third-party penetration testing is performed annually",
    ],
  },
  {
    title: "Security Measures",
    description: "Safeguard your confidential legal data with industry-standard encryption techniques, ensuring end-to-end protection against unauthorized access.",
    keyFeatures: [
      "Customer instances and data are logically separated",
      "All access to data sources, queries, and results is logged and audited",
      "All code is thoroughly reviewed and tested before deployment to production",
      "Perimeter firewalls block unauthorized ports and protocols",
      "All user access is protected by industry standard, role-based authentication",
      "Data source credentials are encrypted and stored in a secure secrets manager",
      "Single Sign On is available and integrates with your secure identity provider",
      "Network vulnerability scans are performed monthly",
      "Third-party penetration testing is performed annually",
    ],
  },
];
