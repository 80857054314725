import React from "react";
import instagramIcon from "../../../../assets/images/icons/Instagram.png";

const Instagram = () => {
  const instagramUrl = "#";
  return (
    <a href={instagramUrl} className="button-disabled">
      <img src={instagramIcon} alt="Instagram icon" />
    </a>
  );
};

export default Instagram;
