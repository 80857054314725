import axios from 'axios';

export const addSubscriber = async (email) => {
  const AWS_API_GATEWAY_URL = 'https://t1u2ysmq1j.execute-api.us-east-1.amazonaws.com/prod/mailchimp';
  const listId = '0c13ef4617'; // JuristAI Newsletter ID in MailChimp
  console.log('trying to add sub to mailchimp....')

  try {
    const response = await axios.post(AWS_API_GATEWAY_URL, { email, listId });
    console.log('response', response);
    return true;
  } catch (error) {
    console.error('error', error);
    return false;
  }
}
