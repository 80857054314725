import aIImage from "./../../../../assets/images/juristAIInsights/ai.png";
import dataSecurityImage from "./../../../../assets/images/juristAIInsights/data-security.png";
import workFollowImage from "./../../../../assets/images/juristAIInsights/legal-work-flow.png";

export const insightsData = [
  {
    date: "July 17, 2023",
    title: "The Future of Legal Practice with AI",
    description: "Discover how AI is transforming the legal industry and what it means for the future of legal practice. Learn about the latest advancements and how they can benefit your firm.",
    image: aIImage,
    link: "#",
  },
  {
    date: "July 17, 2023",
    title: "Enhancing Legal Workflows with Atticus",
    description: "Explore how Atticus can streamline your legal workflows, improve efficiency, and enhance collaboration among your legal team. Get tips on integrating AI into your daily practice.",
    image: dataSecurityImage,
    link: "#",
  },
  {
    date: "July 17, 2023",
    title: "Ensuring Data Security in Legal AI Platforms",
    description: "Understand the importance of data security in AI legal platforms and how JuristAI ensures the protection of your confidential information with advanced security measures.",
    image: workFollowImage,
    link: "#",
  },
];
