import React from "react";
import "./Button.css";

const Button = ({ children, style, onClick, className = "", type = "button" }) => {
  return (
    <button type={type} style={style} className={`button ${className}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
