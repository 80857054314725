import React from "react";
import discordIcon from "../../../../assets/images/icons/discord.png";

const Discord = () => {
  const discordUrl = "#";
  return (
    <a href={discordUrl} className="button-disabled">
      <img src={discordIcon} alt="Discord icon" />
    </a>
  );
};

export default Discord;
