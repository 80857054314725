import React from "react";
import linkedinIcon from "../../../../assets/images/icons/linkedin-green.png";

const Linkedin = () => {
  const linkedinUrl = "https://www.linkedin.com/company/juristai";
  return (
    <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
      <img src={linkedinIcon} alt="Linkedin icon" />
    </a>
  );
};

export default Linkedin;
