import React from "react";
import { Link } from "react-router-dom";
import "./HeroSection.css";
import ArrowLeft from "./../../../../assets/images/icons/arrow-left.png";
import greenLawfirm from "./../../../../assets/images/logos/green-lawfirm.png";
import lexwayLawfirm from "./../../../../assets/images/logos/lexway-lawfirm.png";
import pactLawOffice from "./../../../../assets/images/logos/pact-law-office.png";
import fioreLawFirm from "./../../../../assets/images/logos/fiore-law-firm.png";
import estepationLawFirm from "./../../../../assets/images/logos/estepation-law-firm.png";
import theDepewLawFirm from "./../../../../assets/images/logos/the-depew-law-firm.png";
import YoutubePlaceholder from "../../../shared/Youtube/YoutubePlaceholder";
import EmailSignUp from "../../../shared/NewsLetter/EmailSignUp";

const HeroSection = ({ sectionRef }) => {
  const { recommendationRef, softwareSuitesRef, dataApplicationRef } = sectionRef;

  const youtubeVideoID = "T1OjSJh0evo";
  const handleScroll = (event, sectionRef) => {
    event.preventDefault();
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1>We are revolutionizing the law.</h1>
        <p>
          JuristAI provides several SaaS that use generative AI to help attorneys & the public. Our products span Federal criminal defense, start-up business documentation, and civil litigation. They are one-man AI law firms that support the strategic objectives of human attorneys. These tools
          offer:
        </p>
        <div className="hero-tools">
          <Link to="#" onClick={(e) => handleScroll(e, recommendationRef)}>
            <img src={ArrowLeft} alt="arrow_left" /> Web Dashboard
          </Link>
          <Link to={""} onClick={(e) => handleScroll(e, recommendationRef)}>
            <img src={ArrowLeft} alt="arrow_left" /> Full Motion/Brief Drafting
          </Link>
          <Link to={""} onClick={(e) => handleScroll(e, softwareSuitesRef)}>
            <img src={ArrowLeft} alt="arrow_left" /> Research Citations
          </Link>
          <Link to={""} onClick={(e) => handleScroll(e, softwareSuitesRef)}>
            <img src={ArrowLeft} alt="arrow_left" /> Response Motion Recommendations
          </Link>
          <Link to={""} onClick={(e) => handleScroll(e, softwareSuitesRef)}>
            <img src={ArrowLeft} alt="arrow_left" /> Document Management
          </Link>
          <Link to={""} onClick={(e) => handleScroll(e, dataApplicationRef)}>
            <img src={ArrowLeft} alt="arrow_left" /> Chatbot
          </Link>
          <Link to={""} onClick={(e) => handleScroll(e, dataApplicationRef)}>
            <img src={ArrowLeft} alt="arrow_left" /> Discovery Analysis
          </Link>
          <Link to={""} onClick={(e) => handleScroll(e, dataApplicationRef)}>
            <img src={ArrowLeft} alt="arrow_left" /> Hallucination/Error Detection
          </Link>
        </div>
        <div className="hero-newsletter">
          <EmailSignUp />
        </div>
      </div>
      <div className="hero-section-youtube-container">
        <YoutubePlaceholder videoId={youtubeVideoID} />
      </div>

      <div className="partner-logos">
        <img src={greenLawfirm} alt="Green Lawfirm" />
        <span></span>
        <img src={lexwayLawfirm} alt="Lexway Lawfirm" />
        <span></span>
        <img src={pactLawOffice} alt="Pact Law Office" />
        <span></span>
        <img src={fioreLawFirm} alt="Fiore Law Firm" />
        <span></span>
        <img src={estepationLawFirm} alt="Estepation Law Firm" />
        <span></span>
        <img src={theDepewLawFirm} alt="The Depew Law Firm" />
      </div>
    </section>
  );
};

export default HeroSection;
