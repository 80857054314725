import { Link } from "react-router-dom";
import Button from "./../../../../shared/Button/Button";
import startImage from "./../../../../../assets/images/icons/recommendation-star.png";
import chatImage from "./../../../../../assets/images/icons/chat-logo.png";
import arrowRight from "./../../../../../assets/images/icons/arrow-right.png";
import downShape from "./../../../../../assets/images/icons/down-shape.png";


const TailoredMotion = () =>{

    return (
        <section className="recommendation-section">
        <div className="recommendation-card">
          <div className="atticus-recommends">
            <div className="card-image-wrapper">
              <img src={startImage} alt="Atticus Recommends" className="card-image" />
            </div>
            <div className="atticus-recommends-content">
              <h2 className="atticus-recommends-subtitle">Atticus Recommends</h2>
              <Button className={"button--outline"}>Motion to Extend Time</Button>
              <Button className={"button--outline"}>Motion to Suppress Evidence</Button>
              <Button className={"button--outline"}>Motion to Dismiss Indictment</Button>
              <Button className="recommends-show-more-btn">
                <div className="show-more-btn">
                  <span> Show more </span>
                  <img className="shape-arrow-down" src={downShape} alt="shape down" />
                </div>
              </Button>
            </div>
            <div className="card-image-wrapper-end">
              <img src={startImage} alt="Atticus Recommends" className="card-image" />
            </div>
          </div>

          <div className="recommendation-content">
            <div className="card-image-wrapper">
              <img src={chatImage} alt="Atticus Recommends" className="card-image" />
            </div>
            <h2 className="recommendation-subtitle-card">Tailored Motion Recommendations</h2>
            <p>Receive personalized recommendations for legal motions tailored to your case specifics. Atticus analyzes case details, identifies relevant legal precedents, and generates customized recommendations to strengthen your legal strategy.</p>
            <Link to="#" className="recommendation-link">
              Start Free Trial <img src={arrowRight} alt="Atticus Recommends" className="trial-arrow" />
            </Link>
          </div>
        </div>
      </section>
    );
}

export default TailoredMotion;